import { isDataExportEnabled } from '@gonfalon/dogfood-flags';
import { parseValidParams } from '@gonfalon/filters';

import { defaultFlagFilter } from './internal/defaultFlagFilter';
import { parseCreationDate } from './parseCreationDate';
import { parseEvaluationDate } from './parseEvaluationDate';
import { parseGuardedRolloutState } from './parseGuardedRolloutState';
import { parseSDKAvailability } from './parseSDKAvailability';
import { parseState } from './parseState';
import { parseStatusName } from './parseStatusName';
import { parseTargeting } from './parseTargeting';
import { parseType } from './parseType';
import { UIFlagFilter } from './types';

export function parseUIFlagFilterFromBrowser(searchParams: URLSearchParams) {
  const filters: UIFlagFilter = structuredClone(defaultFlagFilter);

  const validParams = parseValidParams(searchParams);

  if (validParams.has('q')) {
    filters.query = validParams.get('q') ?? undefined;
  }

  if (validParams.has('maintainerId')) {
    filters.maintainerId = validParams.get('maintainerId') ?? undefined;
  }

  if (validParams.has('maintainerTeamKey')) {
    filters.maintainerTeamKey = validParams.get('maintainerTeamKey') ?? undefined;
  }

  if (validParams.has('status')) {
    filters.status = parseStatusName(validParams.get('status'));
  }

  if (validParams.has('type')) {
    filters.type = parseType(validParams.get('type'));
  }

  if (validParams.has('sdkAvailability')) {
    filters.sdkAvailability = parseSDKAvailability(validParams.get('sdkAvailability'));
  }

  if (validParams.has('guardedRollout')) {
    filters.guardedRollout = parseGuardedRolloutState(validParams.get('guardedRollout'));
  }

  if (validParams.has('evaluated')) {
    filters.evaluated = parseEvaluationDate(validParams.get('evaluated'));
  }

  if (isDataExportEnabled()) {
    if (validParams.has('hasDataExport')) {
      filters.hasDataExport = validParams.get('hasDataExport') === 'true';
    }
  }

  if (validParams.has('hasExperiment')) {
    filters.hasExperiment = validParams.get('hasExperiment') === 'true';
  }

  if (validParams.has('tag')) {
    filters.tags = validParams.getAll('tag').filter((tag) => tag !== '');
  }

  if (validParams.has('targeting')) {
    filters.targeting = parseTargeting(validParams.get('targeting'));
  }

  if (validParams.has('state')) {
    filters.state = parseState(validParams.get('state'));
  }

  if (validParams.has('created')) {
    filters.created = parseCreationDate(validParams.get('created'));
  }

  if (validParams.has('contextKindTargeted')) {
    filters.contextKindTargeted = validParams.get('contextKindTargeted') ?? undefined;
  }

  if (validParams.has('contextKindsEvaluated')) {
    filters.contextKindsEvaluated = validParams
      .getAll('contextKindsEvaluated')
      .filter((contextKind) => contextKind !== '');
  }

  if (validParams.has('followerId')) {
    filters.followerId = validParams.get('followerId') ?? undefined;
  }

  if (validParams.has('codeReferences')) {
    filters.codeReferences = validParams.get('codeReferences') === 'true';
  }

  if (validParams.has('applicationEvaluated')) {
    filters.applicationEvaluated = validParams.get('applicationEvaluated') ?? undefined;
  }

  if (validParams.has('segmentTargeted')) {
    filters.segmentTargeted = validParams.get('segmentTargeted') ?? undefined;
  }

  if (validParams.has('releasePipeline')) {
    filters.releasePipeline = validParams.get('releasePipeline') ?? undefined;
  }

  return filters;
}
