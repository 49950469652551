import { type ReactNode } from 'react';
import { type TagGroupProps, type TagListProps, TagGroup as AriaTagGroup, TagList } from 'react-aria-components';
import { FieldError, Label } from '@launchpad-ui/components';

import { Description } from '../Form';

import styles from './TagGroup.module.css';

type Props<T extends object> = Omit<TagGroupProps, 'children'> &
  Pick<TagListProps<T>, 'items' | 'children' | 'renderEmptyState'> & {
    label?: string;
    description?: string;
    errorMessage?: string;
    renderAddTrigger?: (() => ReactNode) | undefined;
  };

export function TagGroup<T extends object>({
  items,
  label,
  description,
  errorMessage,
  renderEmptyState,
  renderAddTrigger,
  children,
  ...props
}: Props<T>) {
  return (
    <div className={styles.container}>
      <AriaTagGroup {...props} className={styles.group}>
        {label && <Label className={styles.label}>{label}</Label>}
        <TagList className={styles.list} items={items} renderEmptyState={renderEmptyState}>
          {children}
        </TagList>
        {description && <Description>{description}</Description>}
        {errorMessage && <FieldError>{errorMessage}</FieldError>}
      </AriaTagGroup>
      {renderAddTrigger && (
        <>
          <span className={styles.symbol} aria-hidden="true">
            ·
          </span>
          <div className={styles.add}>{renderAddTrigger()}</div>
        </>
      )}
    </div>
  );
}
